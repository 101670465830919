<template>
    <s-form @submit="addItem" >
        <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Embryo Stock</h2>
        <div class="row mt-4">
            <div class="col-lg-2">
                <validated-input class="text-black field-required" label="Embryo No" v-model="model.embryo_no" :rules="{required:true}"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-ajax-vue-select class="text-black c-input-select field-required"
                                           :url="embriyoBreedOptionURL"
                                           label="Embryo Breed" v-model="model.embryo_breed" :rules="{required:true}"></validated-ajax-vue-select>
            </div>
            <div class="col-lg-2">
                <validated-select
                    :options="embryotypeOptions"
                    class="text-black c-input-select field-required" label="Embryo Type" v-model="model.embryo_type" :rules="{required:true}"></validated-select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3 mb-2">
                <h6 class="font-poppins-semibold text-primary">Dam Details</h6>
            </div>
            <div class="col-lg-2">
                <validated-ajax-vue-select class="text-black field-required c-input-select" label="Dam No"
                                           :url="cowOptionURL"
                                           v-model="model.dam_no" :rules="{required:true}" @input="loadDetailsDam"></validated-ajax-vue-select>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Dam Name" disabled v-model="model.dam_name"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Dam Breed" disabled v-model="model.dam_breed"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Dam's Yield" disabled v-model="model.dam_yield"></validated-input>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3 mb-2">
                <h6 class="font-poppins-semibold text-primary">Sire Details</h6>
            </div>
            <div class="col-lg-2">
                <validated-ajax-vue-select class="text-black field-required c-input-select"
                                           :url="bullOptionURL"
                                           label="Sire No" v-model="model.sire_no" :rules="{required:true}" @input="loadDetailsSire"></validated-ajax-vue-select>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Sire Name" disabled v-model="model.sire_name"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Sire Breed" disabled v-model="model.sire_breed"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Sire Daughter Yield" disabled v-model="model.sire_yield"></validated-input>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-2">
                <validated-input class="text-black" label="Embryo Grade" v-model="model.embryo_grade"></validated-input>
            </div>
            <div class="col-lg-2">
                <validated-input class="text-black" label="Embryo Location" v-model="model.embryo_location"></validated-input>
            </div>
        </div>
        <div class="row mt-lg-4">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4 mr-3"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome"  design="basic-b" class="px-4"></btn>
                    </div>
                </div>
            </div>
        </div>
    </s-form>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import masterURLs from '../../data/masterURLs';

export default {

    name: 'EmbryoStockPage',
    data () {
        return {
            embriyoBreedOptionURL: masterURLs.master.breed.vueSelect,
            cowOptionURL: urls.cows.vueSelect + '?no_cow_type=Young Female',
            bullOptionURL: urls.bulls.vueSelect + '?status=Regularized',
            URL: urls.embryoStock.addEdit,
            embryotypeOptions: [
                { label: 'Imported', value: 'Imported' },
                { label: 'MOET', value: 'MOET' },
                { label: 'IVF', value: 'IVF' }
            ],
            loading: false,
            model: {
                cow: '',
                bull: '',
                embryo_no: '',
                embryo_breed: '',
                embryo_type: '',
                dam_no: '',
                dam_name: '',
                dam_breed: '',
                dam_yield: '',
                sire_no: '',
                sire_name: '',
                sire_breed: '',
                sire_yield: '',
                embryo_grade: '',
                embryo_location: ''

            }
        };
    },
    methods: {
        async loadDetailsDam () {
            const response = await axios.form(urls.cows.details, { id: this.model.dam_no });
            const data = response.data.data;
            this.model.dam_name = data.cowname;
            this.model.dam_breed = data.breed.breed_name;
            this.model.dam_yield = data.damlacyield;
            console.log('Data', data);
        },
        async loadDetailsSire () {
            const response = await axios.form(urls.bulls.details, { id: this.model.sire_no });
            const data = response.data.data;
            this.model.sire_name = data.bull_name;
            this.model.sire_breed = data.breed.breed_name;
            this.model.sire_yield = data.siredaughteryield;
            console.log('Data', data);
        },
        BackToHome () {
            this.$router.push({ path: '/embryo-transfer/' });
        },
        async addItem () {
            try {
                this.model.cow = this.model.dam_no;
                this.model.bull = this.model.sire_no;
                this.loading = true;
                console.log(this.URL);
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/embryo-transfer/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
